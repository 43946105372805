<template>
  <a-layout-header class="header">
    <div class="left"></div>
    <div class="middle"></div>
    <div class="right">
      <UserNotifications />
      <a-avatar class="avatar" size="large" @click="showDrawer">
        <template #icon>
          <UserOutlined/>
        </template>
      </a-avatar>
    </div>
  </a-layout-header>
  <UserDrawer @visibleChanged="visible = $event" :drawerVisibility="visible"/>
</template>

<script>
import {defineComponent} from 'vue';
import UserDrawer from "@/components/_layouts/Static/UserDrawer";
import {UserOutlined} from "@ant-design/icons-vue";
import UserNotifications from "@/components/UserNotifications/UserNotifications";

export default defineComponent({
  data() {
    return {
      visible: false
    };
  },
  components: {
    UserOutlined,
    UserDrawer, UserNotifications
  },
  methods: {
    showDrawer() {
      this.visible = true;
    }
  }
});
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding-left: 15px;
}

.avatar {
  cursor: pointer;
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 85px;
}
</style>
