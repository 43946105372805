<template>
  <a-popover placement="bottom">
    <template #content>
      <a-tabs v-model:activeKey="activeKey">

        <a-tab-pane key="1" tab="Bildirimlerim">
          <NotificationList :authUser="authUser"/>
        </a-tab-pane>

        <a-tab-pane key="2" tab="Mesajlar ( Çok Yakında! )" disabled/>

      </a-tabs>
    </template>
    <a-badge :count="notificationCount">
      <BellOutlined class="bell-icon"/>
    </a-badge>
  </a-popover>
</template>

<script>
import {BellOutlined} from "@ant-design/icons-vue"
import {ref} from "vue";
import NotificationList from "@/components/UserNotifications/NotificationList";

export default {
  setup() {
    return {
      activeKey: ref('1')
    }
  },
  data() {
    return {
      authUser: this.$store.getters.authUserInfo
    }
  },
  components: {
    BellOutlined,
    NotificationList
  },
  computed: {
    notificationCount() {
      if (typeof this.authUser.notifications !== "undefined"){
        let count = this.authUser.notifications.filter(v => {
          return v.options.read === false
        })

        return count.length
      }
    }
  }
}
</script>

<style scoped>
.bell-icon {
  font-size: 20px;
}
</style>