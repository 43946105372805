<template>
  <a-layout-footer style="text-align: center">
    Vu3JS ©2021 Created by <a href="https://connectprof.com" target="_blank">ConnectProf</a>
  </a-layout-footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped></style>
