<template>
  <div class="flex-reverse">
    <div v-if="authUser.notifications.length > 0"
         v-for="notification in authUser.notifications"
         :style="notification.options.hide === true ? 'display:none' : null"
    >
      <a-alert
          :message="notification.content.title"
          :description="notification.content.description + ' | ' + unixToDate(notification.createdAt)"
          :type="notification.type"
          :row-key="notification.key"
          show-icon
      />
    </div>
    <div v-else>
      <a-alert
          message="Bildirim yok .s"
          description="Henüz bir bildiriminiz yok."
          type="warning"
          show-icon
      />
    </div>
    <div class="notification-btn" v-if="authUser.notifications.length > 0">
      <a-button size="small" :disabled="notificationCount === 0" @click="notificationsStatus(false)" type="primary">Tüm
        Bildirimleri Okundu Say
      </a-button>
      <a-button size="small" :disabled="hideNotify" @click="notificationsStatus(true)" type="danger">Tüm Bildirimleri
        Gizle
      </a-button>
    </div>
  </div>
</template>

<script>
import {unixToDate} from "@/helpers/unixToDate";
import instance from "@/http/axios";
import {message} from "ant-design-vue";

export default {
  setup() {
    return {unixToDate}
  },
  props: ['authUser'],
  methods: {
    notificationsStatus(hide) {
      let formData = new FormData();

      if (hide) {
        formData.append('hide', true)
        this.hideNotify = true
        this.authUser.notifications.map(v => {
          v.options.read = true
          v.options.hide = true
        })
      } else {
        this.authUser.notifications.map(v => {
          v.options.read = true
        })
      }

      formData.append('notifications', JSON.stringify(this.authUser.notifications))

      instance.post('/user/notificationStatus', formData).then(response => {
        if (response.data.code === 200)
          message.success(response.data.msg)
        else
          message.error(response.data.msg)
      })

    },
  },
  data() {
    return {
      hideNotify: false
    }
  },
  computed: {
    notificationCount() {
      if (typeof this.authUser.notifications !== "undefined") {
        let count = this.authUser.notifications.filter(v => {
          return v.options.read === false
        })

        count.length > 0 ? this.hideNotify = false : null

        return count.length
      }
    }
  }
}
</script>

<style scoped>
.flex-reverse {
  display: flex;
  flex-direction: column-reverse;
  max-height: 550px;
  max-width: 550px;
  overflow: auto;
}

.ant-alert {
  margin-bottom: 7px;
}

.notification-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
}
</style>