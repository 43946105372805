<template>
  <a-layout id="components-layout-demo-side" style="min-height: 100vh">
    <keep-alive>
      <Navbar/>
    </keep-alive>
    <a-layout>
      <Header/>
      <a-layout-content class="content">
        <!-- extend edilen dosya içinden <template v-slot:breadcrumb> şeklinde bind edilmeli -->
        <slot name="breadcrumb"></slot>

        <!-- extend edilen dosya içinden <template v-slot:content> şeklinde bind edilmeli -->
        <slot name="content"></slot>
      </a-layout-content>
      <Footer/>
    </a-layout>
  </a-layout>
  <teleport to="#mTitle">
    <slot name="mTitle"></slot>
  </teleport>
</template>

<script>
import Header from "@/components/_layouts/Static/Header";
import Navbar from "@/components/_layouts/Static/Navbar";
import Footer from "@/components/_layouts/Static/Footer";

export default {
  components: {Footer, Navbar, Header}
};
</script>

<style scoped>
.content {
  margin: 0 16px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
