<template>
  <a-drawer
      :title="this.$store.getters.authUserInfo.name"
      placement="right"
      :closable="true"
      v-model:visible="drawerVisibility"
      :after-visible-change="visibleChange"
      width="400px"
  >

    <NotificationList :auth-user="authUser" style="margin-bottom: 10px"/>

    <a-button type="primary" @click="authDestroy">Çıkış yap</a-button>
  </a-drawer>
</template>

<script>
import NotificationList from "@/components/UserNotifications/NotificationList";

export default {
  components: {
    NotificationList
  },
  data(){
    return {
      authUser: this.$store.getters.authUserInfo
    }
  },
  props: ["drawerVisibility"],
  methods: {
    visibleChange(val) {
      this.$emit("visibleChanged", val);
    },
    authDestroy() {
      this.$store.dispatch("authDestroy");
    }
  }
};
</script>
