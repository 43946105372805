<template>
  <a-layout-sider v-model:collapsed="collapsed" collapsible="">
    <div class="logo">
      <img src="../../../assets/logo-header-big.svg" alt="logo" width="195">
    </div>
    <a-menu theme="dark" v-model:selectedKeys="selectedKeys" mode="inline">
      <a-menu-item key="dashboard">
        <router-link to="/">
          <home-filled />
          <span>Ana Sayfa</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="customers" v-if="permission.customers">
        <router-link to="/customers">
          <user-outlined />
          <span>Müşterilerim</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="accouting" v-if="permission.accouting">
        <router-link to="/accouting">
          <user-outlined />
          <span>Müşteriler</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="support" v-if="permission.support">
        <router-link to="/support">
          <user-outlined />
          <span>Müşteriler</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="price" v-if="(permission.price && authUser.departmentInfo === 'sales' && authUser.departmentSuperUser) || authUser.departmentInfo === 'super'">
        <router-link to="/price">
          <ShopOutlined />
          <span>Fiyatlar</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="users" v-if="permission.users">
        <router-link to="/users">
          <UsergroupAddOutlined />
          <span>Kullanıcılar</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="settings" v-if="permission.settings">
        <router-link to="/settings">
          <SettingOutlined />
          <span>Ayarlar</span>
        </router-link>
      </a-menu-item>

      <!--      <a-sub-menu key="customers" v-if="permission.customers">-->
      <!--        <template #title>-->
      <!--          <span>-->
      <!--            <user-outlined />-->
      <!--            <span>Müşteriler</span>-->
      <!--          </span>-->
      <!--        </template>-->
      <!--        <a-menu-item key="customers">-->
      <!--          <router-link to="/customers">-->
      <!--            <span>Tüm Müşteriler</span>-->
      <!--          </router-link>-->
      <!--        </a-menu-item>-->
      <!--        <a-menu-item key="assigned_customers">-->
      <!--          <router-link to="/assigned_customers">-->
      <!--            <span>Atanmış Müşteriler</span>-->
      <!--          </router-link>-->
      <!--        </a-menu-item>-->
      <!--      </a-sub-menu>-->
    </a-menu>
  </a-layout-sider>
</template>

<script>
import { HomeFilled, UserOutlined, SettingOutlined, UsergroupAddOutlined, ShopOutlined } from "@ant-design/icons-vue";

export default {
  name: "Navbar",
  components: {
    HomeFilled, UserOutlined, SettingOutlined, UsergroupAddOutlined, ShopOutlined
  },
  data() {
    return {
      collapsed: false,
      selectedKeys: [this.$route.name],
      authUser: {}
    };
  },
  computed: {
    permission() {
      let permission = { ...this.$store.getters.authUserInfo.permissions };
      this.authUser = this.$store.getters.authUserInfo

      return {
        dashboard: typeof permission.dashboard !== "undefined" ? permission.dashboard : false,
        customers: typeof permission.customers !== "undefined" ? permission.customers : false,
        accouting: typeof permission.accouting !== "undefined" ? permission.accouting : false,
        support: typeof permission.support !== "undefined" ? permission.support : false,
        settings: typeof permission.settings !== "undefined" ? permission.settings : false,
        users: typeof permission.users !== "undefined" ? permission.users : false,
        price: typeof permission.price !== "undefined" ? permission.price : false,
      };
    }
  }
};
</script>